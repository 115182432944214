import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as d3 from 'd3';

@Component({
  selector: 'app-circular-plot',
  templateUrl: './circular-plot.component.html',
  styleUrls: ['./circular-plot.component.css'],
})
export class CircularPlotComponent implements OnInit {
  constructor(private router: Router) {}

  public region: String;
  public variable: String;

  public notOverview() {
    return this.router.url !== '/overview';
  }

  ngOnInit() {
    this.region = 'stadt';
    this.variable = 'income';
    this.start();
    this.createSvg();
  }
  data;

  // set the dimensions and margins of the graph
  private readonly MARGIN = { top: 0, right: 0, bottom: 0, left: 0 };
  private readonly WIDTH = 650 - this.MARGIN.left - this.MARGIN.right;
  private readonly HEIGHT = 650 - this.MARGIN.top - this.MARGIN.bottom;
  private readonly INNERRADIUS = 90;
  private readonly OUTER_RADIUS = Math.min(this.WIDTH, this.HEIGHT) / 2 - 40; // the outerRadius goes from the middle of the SVG area to the border
  private svg;

  public update() {
    d3.csv(
      'assets/data/' + this.region + '_' + this.variable + '_based.csv'
    ).then((data) => {
      this.data = data;
      this.updateChart();
    });
  }

  public start() {
    d3.csv(
      'assets/data/' + this.region + '_' + this.variable + '_based.csv'
    ).then((data) => {
      this.data = data;
      this.addLegendLabels();
      this.updateChart();
    });
  }

  private updateChart() {
    // Scales
    const x = d3
      .scaleBand()
      .range([0, 2 * Math.PI]) // X axis goes from 0 to 2pi = all around the circle. If I stop at 1Pi, it will be around a half circle
      .align(0) // This does nothing
      .domain(this.data.map((d) => d.var)); // The domain of the X axis is the list of states.
    const y = d3
      .scaleRadial()
      .range([this.INNERRADIUS, this.OUTER_RADIUS]) // Domain will be define later.
      .domain([0, 130]); // Domain of Y is from 0 to the max seen in the data

    let u = this.svg.selectAll('path').data(this.data);

    u.enter()
      .append('path') // Add a new rect for each new elements
      .merge(u) // get the already existing elements as well
      .transition() // and apply changes to all of them
      .duration(1000)
      .attr('fill', (d) => {
        return this.getColor(d.typ);
      })
      .attr(
        'd',
        d3
          .arc() // imagine your doing a part of a donut plot
          .innerRadius(this.INNERRADIUS)
          .outerRadius((d) => {
            return y(d[this.variable + '_based']);
          })
          .startAngle(function (d: any) {
            return x(d.var);
          })
          .endAngle((d: any) => {
            return x(d.var) + x.bandwidth();
          })
          .padAngle(0.01)
          .padRadius(this.INNERRADIUS)
      );

    // If less group in the new dataset, I delete the ones not in use anymore
    u.exit().remove();

    //update labels
    let l = this.svg.selectAll('.labels').data(this.data);

    l.enter()
      .append('g') // Add a new rect for each new elements
      .merge(l) // get the already existing elements as well
      .transition() // and apply changes to all of them
      .duration(1000)
      .attr('class', 'labels')
      .attr('text-anchor', (d) => {
        return (x(d.var) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) <
          Math.PI
          ? 'end'
          : 'start';
      })
      .attr('transform', (d) => {
        return (
          'rotate(' +
          (((x(d.var) + x.bandwidth() / 2) * 180) / Math.PI - 90) +
          ')' +
          'translate(' +
          (y(this.getBiggerData(d)) + 10) +
          ',0)'
        );
      });
    l.select('text').remove();
    l.append('text')
      .text((d) => {
        return d.var;
      })
      .attr('transform', (d) => {
        return (x(d.var) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) <
          Math.PI
          ? 'rotate(180)'
          : 'rotate(0)';
      })
      .style('font-size', '11px')
      .attr('alignment-baseline', 'middle');
    l.exit().remove();

    this.addReferenceData();
    this.addTooltip();
  }

  private addLegendLabels() {
    // Scales
    const x = d3
      .scaleBand()
      .range([0, 2 * Math.PI]) // X axis goes from 0 to 2pi = all around the circle. If I stop at 1Pi, it will be around a half circle
      .align(0) // This does nothing
      .domain(this.data.map((d) => d.var)); // The domain of the X axis is the list of states.
    const y = d3
      .scaleRadial()
      .range([this.INNERRADIUS, this.OUTER_RADIUS]) // Domain will be define later.
      .domain([0, 130]); // Domain of Y is from 0 to the max seen in the data

    // Add the labels
    this.svg
      .append('g')
      .selectAll('g')
      .data(this.data)
      .enter()
      .append('g')
      .attr('class', 'labels')
      .attr('text-anchor', (d) => {
        return (x(d.var) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) <
          Math.PI
          ? 'end'
          : 'start';
      })
      .attr('transform', (d) => {
        return (
          'rotate(' +
          (((x(d.var) + x.bandwidth() / 2) * 180) / Math.PI - 90) +
          ')' +
          'translate(' +
          (y(this.getBiggerData(d)) + 10) +
          ',0)'
        );
      })
      .append('text')
      .text((d) => {
        return d.var;
      })
      .attr('transform', (d) => {
        return (x(d.var) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) <
          Math.PI
          ? 'rotate(180)'
          : 'rotate(0)';
      })
      .style('font-size', '11px')
      .attr('alignment-baseline', 'middle');

    let legendLabels = [
      'Herkunft',
      'Geschlecht',
      'Bildung',
      'Haushaltstyp',
      'Erwerbsstatus',
      'Branche/Berufe',
    ];
    let legend = this.svg
      .append('g')
      .selectAll('g')
      .data(legendLabels)
      .enter()
      .append('g')
      .attr('transform', function (d, i) {
        return 'translate(-50,' + (i - 5 / 1.59) * 19 + ')';
      });

    legend
      .append('rect')
      .attr('width', 15)
      .attr('height', 15)
      .attr('fill', (d) => {
        return this.getColor(d);
      });

    legend
      .append('text')
      .attr('x', 24)
      .attr('y', 9)
      .attr('dy', '0.35em')
      .attr('font-size', '12')
      .text(function (d) {
        return d;
      });
  }

  private addTooltip() {
    // create a tooltip
    let tooltip = d3
      .select('#plot')
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '8px')
      .style('font-size', '12px')
      .style('background-color', 'black');
    // Three function that change the tooltip when user hover / move / leave a cell
    let mouseover = (d) => {
      if (this.isNotLegend(d.srcElement.__data__)) {
        tooltip.transition().duration(200).style('opacity', 1);
        tooltip.html(
          "<span style='color: white'>" +
            Math.round(
              (Number(d.srcElement.__data__[this.variable + '_based']) +
                Number.EPSILON) *
                10
            ) /
              10 +
            '%</span>' +
            "<span style='color:white'> " +
            d.srcElement.__data__.tooltip_stadt_land +
            '...' +
            '</span></br>' +
            "<span style='color: white'>" +
            Math.round(
              (Number(d.srcElement.__data__.ref) + Number.EPSILON) * 10
            ) /
              10 +
            '% </span>' +
            "<span style='color:white'>" +
            d.srcElement.__data__.tooltip_ref +
            '...</br>' +
            '...' +
            d.srcElement.__data__.tooltip_var +
            '</span>'
        );
      }
    };
    let mousemove = (d) => {
      tooltip
        .style('width', '180px')
        .style('left', d3.pointer(event, '#customTooltip')[0] - 100 + 'px')
        .style('top', d3.pointer(event, '#customTooltip')[1] + 30 + 'px');
    };
    let mouseleave = (d) =>
      tooltip.transition().duration(200).style('opacity', 0);

    this.svg
      .on('mouseover', mouseover)
      .on('mousemove', mousemove)
      .on('mouseleave', mouseleave);
  }

  private createSvg(): void {
    this.svg = d3
      .select('#plot')
      .append('svg')
      .attr('id', 'customTooltip')
      .attr('viewBox', '0 0 ' + this.WIDTH + ' ' + this.HEIGHT)
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr(
        'transform',
        'translate(' +
          (this.WIDTH / 2 + this.MARGIN.left) +
          ',' +
          (this.HEIGHT / 2 + this.MARGIN.top) +
          ')'
      );
  }

  isNotLegend(dataText: string) {
    switch (dataText) {
      case 'Herkunft':
        return false;
        break;
      case 'Geschlecht':
        return false;
        break;
      case 'Bildung':
        return false;
        break;
      case 'Haushaltstyp':
        return false;
        break;
      case 'Erwerbsstatus':
        return false;
        break;
      case 'Branche/Berufe':
        return false;
        break;
      default:
        return true;
    }
  }

  getColor(typ) {
    switch (typ) {
      case 'Herkunft':
        return '#556455';
        break;
      case 'Geschlecht':
        return '#506e96';
        break;
      case 'Bildung':
        return '#645078';
        break;
      case 'Haushaltstyp':
        return '#786450';
        break;
      case 'Erwerbsstatus':
        return '#b41428';
        break;
      case 'Branche/Berufe':
        return '#faa500';
        break;
      default:
      // code block
    }
  }

  private getBiggerData(d) {
    return Math.round(
      (Number(d[this.variable + '_based']) + Number.EPSILON) * 100
    ) /
      100 >=
      Math.round((Number(d['ref']) + Number.EPSILON) * 100) / 100
      ? d[this.variable + '_based']
      : d['ref'];
  }
  private addReferenceData() {
    // Scales
    let x = d3
      .scaleBand()
      .range([0, 2 * Math.PI]) // X axis goes from 0 to 2pi = all around the circle. If I stop at 1Pi, it will be around a half circle
      .align(0) // This does nothing
      .domain(
        this.data.map((d) => {
          return d.var;
        })
      ); // The domain of the X axis is the list of states.
    let y = d3
      .scaleRadial()
      .range([this.INNERRADIUS, this.OUTER_RADIUS]) // Domain will be define later.
      .domain([0, 130]); // Domain of Y is from 0 to the max seen in the data

    // Add the bars
    this.svg
      .append('g')
      .selectAll('path')
      .data(this.data)
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', (d) => {
        return d['ref'] ? 'black' : 'none';
      })
      .attr('stroke-width', 2)
      .attr(
        'd',
        d3
          .arc() // imagine your doing a part of a donut plot
          .innerRadius((d) => {
            return y(d['ref']);
          })
          .outerRadius((d) => {
            return y(d['ref']);
          })
          .startAngle((d: any) => {
            return x(d.var);
          })
          .endAngle((d: any) => {
            return x(d.var) + x.bandwidth();
          })
          .padAngle(0.01)
          .padRadius(this.INNERRADIUS)
      );
  }
}
