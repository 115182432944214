<div *ngIf="notOverview()"> <div class="präsentierbalken" ></div>
<div class="additional-info">

  <p><strong>Lesehilfe:</strong> Die Abbildung zeigt die Zusammensetzung der Armutsbevölkerung im Vergleich zur Gesamtbevölkerung (schwarze Linie).
    <em>Lesebeispiel, Bildung/Einkommen:</em> Von den Armutsbetroffenen in der Stadt weisen 7.6% keinen obligatorischen Schulabschluss aus.
    Auf dem Land sind es 7.9%. Im Vergleich zur Gesamtbevölkerung (3.1%) sind Menschen ohne obligatorischen Schulabschluss sowohl in Stadt und Land übervertreten.</p>
  <p><strong>Methodische Erläuterungen: </strong> <em>«Einkommen»</em> misst Armut gemäss dem Ansatz der absolute Einkommensarmut. Alle Personen in Haushalten mit einem Einkommen unterhalb der Grenze des sozialen Existenzminimums gemäss SKOS gelten als arm.
    <em>«inkl. Reserven»</em> misst Armut zusätzlich unter Einbezug von finanziellen Reserven. Als arm gilt erst wer neben geringen Einkommen keine finanziellen Reserven zur Deckung des Haushaltsbedarfes über 12 Monate verfügt.</p>
  <p>Quelle: verknüpfte Steuerdaten Kanton Bern 2015, Berechnungen BFH</p></div></div>
  
 

