import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {
    this.region = 'stadt';
    this.variable = 'income';
  }
  public region: String;
  public variable: String;

  public changeRegion() {
    this.router.navigate([this.region + '-' + this.variable]);
  }

  public changeVariable() {
    this.router.navigate([this.region + '-' + this.variable]);
  }
  public notOverview() {
    return this.router.url !== '/overview';
  }
}
