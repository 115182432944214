<div id="visualization">
  <div id="plot"></div>

</div>

<div class="footer" *ngIf="notOverview()">
  <div class="buttons">
    <mat-button-toggle-group class="region" name="region" aria-label="Region" [(value)]="region">
    <mat-button-toggle (click)="update()" value="land">Land</mat-button-toggle>
    <mat-button-toggle (click)="update()" value="stadt">Stadt</mat-button-toggle>
  </mat-button-toggle-group>
  <mat-button-toggle-group class="evaluation-base" name="evaluation-base" aria-label="Auswertungsart" [(value)]="variable">
    <mat-button-toggle (click)="update()" value="income" >Einkommen</mat-button-toggle>
    <mat-button-toggle (click)="update()" value="asset">inkl. Reserven</mat-button-toggle>
  </mat-button-toggle-group>

  <a class="overview" href="overview" target="_blank">Übersicht</a></div>
 