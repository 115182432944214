import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StadtIncomeBasedComponent } from './stadt-income-based/stadt-income-based.component';
import { LandIncomeBasedComponent } from './land-income-based/land-income-based.component';
import { LandAssetBasedComponent } from './land-asset-based/land-asset-based.component';
import { StadtAssetBasedComponent } from './stadt-asset-based/stadt-asset-based.component';
import { FooterComponent } from './footer/footer.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { OverviewComponent } from './overview/overview.component';
import { CircularPlotComponent } from './circular-plot/circular-plot.component';

@NgModule({
  declarations: [
    AppComponent,
    StadtIncomeBasedComponent,
    LandIncomeBasedComponent,
    LandAssetBasedComponent,
    StadtAssetBasedComponent,
    FooterComponent,
    OverviewComponent,
    CircularPlotComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatButtonToggleModule,
    MatButtonModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
