<div>
  <div class="top"><h3>Sozialstruktur der Armutsbevölkerung</h3>
    <a class="pdf" mat-raised-button href="assets/BfhCircularPlot.pdf" target="_blank">PDF</a></div>
  <div class="overview">
     <div class="col">
          <app-land-income-based></app-land-income-based>
          <span class="title">Land, Einkommensarmut</span>
          <app-stadt-income-based></app-stadt-income-based>
          <span class="title">Stadt, Einkommensarmut</span>
        </div>
          
      <div class="col">
          <app-land-asset-based></app-land-asset-based>
          <span class="title">Land, Einkommensarmut und fehlende finanzielle Reserven</span>
          <app-stadt-asset-based></app-stadt-asset-based>
          <span class="title">Stadt, Einkommensarmut und fehlende finanzielle Reserven</span> 
      </div> 
      </div>
      <p>Quelle: Hümbelin, O., Hobi, L. und Fluder, R. 2021. “Rich Cities, Poor Countryside? Social Structure of The Poor and Poverty Risks In Urban and Rural Places In An Affluent Country”. BFH.</p>
</div>